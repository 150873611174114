<template>
  <div class="home">
    <HelloWorld msg="Welcome to 世泊云管理中心"/>
    这是管理员 欢迎页面
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>